import React from "react";
import { Link } from "gatsby";

/**
 * Mini post preview component
 *
 * Shows the title of a blog post and a date published. For the archive page to
 * show everything I've written in a more condensed form.
 */
const MiniPreview = ({ node, title, tag }) => {
  return (
    <article key={node.slug}>
      <header>
        <h2 className="miniPreviewHeader" style={{}}>
          <Link
            style={{
              boxShadow: "none"
            }}
            to={`/blog/${node.slug}/`}
          >
            {title}
          </Link>
        </h2>
        <p className="miniPreviewSubheader">
          {node.published_at} • {node.reading_time + 1} min •{" "}
          {tag == null ? "" : tag.name}{" "}
        </p>
      </header>
    </article>
  );
};

export default MiniPreview;
