import React from "react";
import { graphql } from "gatsby";
import { Block } from "glamor/jsxstyle";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import MiniPreview from "../components/MiniPreview";
import SEO from "../components/SEO";

/**
 * Blog template
 *
 * Shows a list of all of my most recent blog posts ranked by date with
 * a link to access a full archive of everything I've ever written. This
 * will automatically pull data from the Ghost API to stay up to date.
 */
const Blog = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allGhostPost.edges;

  return (
    <Layout title={siteTitle}>
      <h1 style={{ fontSize: "2.6rem", marginBottom: "1.5rem" }}>Blog</h1>
      <SEO
        title={"Blog | Conor Dewey"}
        description={`This a list of everything I’ve written. Subscribe
          to my newsletter to make sure you don't
          miss any new posts.`}
        pathname={"blog"}
      />
      <title>{"Blog | Conor Dewey"}</title>
      <section>
        <Block
          className="pageTemplateSubheader"
          fontSize="1.3rem"
          lineHeight="2rem"
          fontWeight="500"
          paddingBottom="2rem"
        >
          This is where I write. Subscribe to my{" "}
          <a
            href="https://conor.substack.com/welcome"
            target="_blank"
            rel="noopener noreferrer"
          >
            newsletter
          </a>{" "}
          to get future posts straight to your inbox.
        </Block>
      </section>
      {posts.map(({ node }) => {
        const title = node.title || node.slug;
        return <MiniPreview node={node} title={title} tag={node.primary_tag} />;
      })}
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { title: { ne: "Data schema" } }
    ) {
      edges {
        node {
          title
          slug
          published_at(formatString: "MMM DD, YYYY")
          reading_time
          excerpt
          primary_tag {
            name
          }
        }
      }
    }
  }
`;
